<template>
  <div>
    <h2 class="h4" v-html="$T('title')"/>

    <hr/>

    <b-row>
      <!-- TODO: Benutzerbild -->

      <b-col v-bind="{ [$root.mobile.up.key]: 3 }">
        <h3 class="badge badge-secondary badge-pill badge-title">{{ $T('usertitle') }}</h3>
      </b-col>

      <b-col v-bind="{ [$root.mobile.up.key]: 9 }">
        <entity-edit-form
          :isBusy="is.loading"
          :entityKey="entityKey"
          :entityId="entity.value.id || ''"
          :translations="(TRANSLATIONS.editform || {})[entityKey]"
          @entity:update="entity => updateEntity(entityKey, entity)"
        />
      </b-col>

      <b-col cols="12"><hr/></b-col>

      <template v-for="(subEntityKey, sIndex) in subEntityKeys">
        <b-col :key="`${subEntityKey}-title`" v-bind="{ [$root.mobile.up.key]: 3 }">
          <h3 class="badge badge-secondary badge-pill badge-title">{{ $T(`${subEntityKey}Title`) }}</h3>
        </b-col>

        <b-col :key="subEntityKey" v-bind="{ [$root.mobile.up.key]: 9 }">
          <template v-for="subentity in subEntities[subEntityKey]">
            <entity-edit-form
              :key="subentity.value.id"
              :isBusy="subIs[subEntityKey].loading"
              :entityKey="subEntityKey"
              :entityId="subentity.value.id || ''"
              :definitionModifier="subEntityDefinitionModifier"
              :translations="(TRANSLATIONS.editform || {})[subEntityKey]"
              @entity:update="entity => updateEntity(subEntityKey, entity)"
            />
          </template>

          <!-- start: FDFC -->
          <template v-if="showEntityAddForm(subEntityKey)">
          <!-- end: FDFC -->
            <hr/>

            <template v-if="entity.value && entity.value.id">
              <entity-add-form
                :entityKey="subEntityKey"
                :parentId="entity.value.id"
                :parentIdKey="entityIdKey"
                :definitionModifier="subEntityDefinitionModifier"
                :translations="(TRANSLATIONS.addform || {})[subEntityKey]"
              />
            </template>
          <!-- start: FDFC -->
          </template>
          <!-- end: FDFC -->

          <hr v-if="sIndex < subEntityKeys.length - 1"/>
        </b-col>
      </template>

      <b-col cols="12"><hr/></b-col>

      <b-col v-bind="{ [$root.mobile.up.key]: 3 }">
        <h3 class="badge badge-secondary badge-pill badge-title">{{ $T('changerequest.title') }}</h3>
      </b-col>

      <b-col v-bind="{ [$root.mobile.up.key]: 9 }">
        <c-loader v-if="is.loading || Object.values(subIs).map(s => s.loading).includes(true)" inline scale="2.5"/>

        <entity-changerequests-table
          v-else
          :definitions="definitions"
          :entities="entities"
          :translations="TRANSLATIONS.changerequest"
        >
          <template v-slot:killconfirmation-text=""><slot name="changerequests-killconfirmation-text"/></template>
        </entity-changerequests-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { CHANGEREQUEST_STATES, ADDRESS_TYPES, STOREMODULE_ENDPOINT_MAP } from '@/assets/js/config/server'

import string from '@/assets/js/helper/string'

import EntityEditForm from '@/components/authenticated/Entity/EditForm'
import EntityAddForm from '@/components/authenticated/Entity/AddForm'
import EntityChangerequestsTable from '@/components/authenticated/Entity/ChangerequestsTable'

const ENTITY_KEY = 'users'
const SUB_ENTITY_KEYS = ['useraddresses', 'userprofessions']

export default {
  name: 'Users.Detail',
  components: {
    EntityEditForm,
    EntityAddForm,
    EntityChangerequestsTable
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      entityIdKey: `${string.singularize(ENTITY_KEY)}Id`,
      subEntityKeys: SUB_ENTITY_KEYS,
      changeRequestSlotKeys: Object.keys(CHANGEREQUEST_STATES).map(sKey => `changerequest-state-${sKey}`)
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.entityKey}/is`]
    },
    subIs () {
      return this.subEntityKeys.reduce((subEntities, sKey) => Object.assign(subEntities, { [sKey]: this.$store.getters[`${sKey}/is`] }), {})
    },
    entity () {
      return this.$store.getters[`${this.entityKey}/getSelf`] || {}
    },
    subEntities () {
      return this.subEntityKeys.reduce((subEntities, sKey) => Object.assign(subEntities, { [sKey]: this.$store.getters[`${sKey}/get`] }), {})
    },
    definitions () {
      return this.subEntityKeys
        .reduce((subEntities, sKey) => Object.assign(subEntities, { [sKey]: this.$store.getters[`${sKey}/definition`] }), {
          [this.entityKey]: this.$store.getters[`${this.entityKey}/definition`]
        })
    },
    entities () {
      return [].concat(this.entity || [], Object.values(this.subEntities).flat())
    }
  },
  methods: {
    getEntity () {
      this.$store.dispatch(`${this.entityKey}/getSelf`)
        .then(this.getSubEntities)
    },
    getSubEntities () {
      // this.subEntityKeys
      //   .forEach(sKey => {
      //     this.$store.dispatch(`${sKey}/getAll`, { by: { name: getByName, id: this.user.value.id } })
      //   })

      // TODO: Replace the following lines with the ones above, once backend sends complete entity data structure (e.g. { value: { ... }, changeRequests: [ ... ] })
      this.subEntityKeys
        .forEach(sKey => {
          this.$store.commit(`${sKey}/setLoading`, { loading: true })
          this.$store.dispatch(`${sKey}/getAll`, { by: { name: this.entityKey, id: this.entity.value.id } })
            .then(response => {
              let loadedCount = 0

              response
                .forEach(cr => {
                  this.$store.dispatch(`${sKey}/getEntity`, cr.value.id)
                    .then(() => {
                      loadedCount++
                      if (loadedCount >= response.length) this.$store.commit(`${sKey}/setLoading`, { loading: false })
                    })
                })
            })
        })
    },
    // start FDFC - fast and dirty frontend customization
    showEntityAddForm (entityKey) {
      if (entityKey === 'useraddresses') {
        if (this.subIs.useraddresses.loading) return false

        const existingAddressTypes = this.subEntities.useraddresses.map(a => a.value.type)
        return !Object.values(ADDRESS_TYPES).every(t => existingAddressTypes.includes(t))
      }

      return true
    },
    subEntityDefinitionModifier (definition = {}) {
      if (definition.name === STOREMODULE_ENDPOINT_MAP.useraddresses) {
        const property = definition.properties.find(p => p.name === 'type')

        property.settings.defaultValue = ADDRESS_TYPES.invoice
        property.settings.disabled = true
        definition.$properties.type.settings.defaultValue = ADDRESS_TYPES.invoice
        definition.$properties.type.settings.disabled = true
      }

      return definition
    },
    // end FDFC
    updateEntity (entityKey, entity) {
      this.$store.dispatch(`${entityKey}/updateEntity`, entity)
    }
  },
  created () {
    this.getEntity()
  }
}
</script>

<style lang="scss"></style>
